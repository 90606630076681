/**
 * import all the modules
 */

import events from './common/events'
import landingCookies from './common/landingCookie'

$('#submit-release-data').on('click', function (){
  var releaseId = $('#form-release-id').val()
  var releaseBuildNR = $('#form-release-build-nr').val()
  var releaseNotes = $('#form-release-build-notes').val()

  let theData = '{"releaseID": "' + releaseId + '", "releaseNr": "' + releaseBuildNR + '", "releaseNotes": "' + releaseNotes + '"}'

  $.ajax({
      url: '/update-release-notes',
      type: "POST",
      data: {
          jdata: theData
      },
      beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
          $('.loading').show()
      }
  })
  .done(function () {
      $('.loading').hide()
      location.reload()
  })
})

$('.releaseFormEdit').on('click', function(e) {
  var releaseDetails = JSON.parse($(this).attr('data-release'))

  $('#form-release-id').val(releaseDetails.id)
  $('#form-release-build-nr').val(releaseDetails.build_nr)
  $('#form-release-build-notes').val(releaseDetails.notes)
})


/** 
 * NOTIFICATIONS
 */
$('.notification').on('click', function (e) {
    e.preventDefault()
    
    var redirectUrl = $(this).attr('href')
    var needsToUpdate = $(this).hasClass('unread')

    if (needsToUpdate) {

      var id = $(this).attr('id')

      let theData = '{"id": "' + id + '"}'

      $.ajax({
          url: '/notifications/dismiss',
          type: "POST",
          data: {
              jdata: theData
          },
          beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));
          }
      })
    }

    window.location.href = redirectUrl
})


const init = () => {
  /**
   * Events listener
   */
  $('#year').text(new Date().getFullYear())
  $('.loading').hide()
  events.initEvents()
  landingCookies.checkLandingCookie()
}

/**
 * RUN THE APP
 */

init()
