/**
 * module that contain some reusable functions
 */
 var layerconfig
if ($('#layerconfig').length) {
    layerconfig = JSON.parse($('#layerconfig').val())
}
 let cachecap
 let mmcachecap
 let failcap = ''
 //test on dev let failoverUrl = 'http://237de1a.online-server.cloud:3000/cgi-bin/miniscale/miniscale?'
 let failoverUrl = 'https://mapserver.traffweb.app/cgi-bin/miniscale/miniscale?'

 /**
 * Get mapping service capabilities
 */
 const loadCacheCapabilities = () => {
    return new Promise((resolve, reject) => {
        $.ajax({
            crossOrigin: true,
            type: 'GET',
            url: 'https://www.buchananmapping.co.uk/geoserver/gwc/service/wmts?service=WMTS&request=GetCapabilities',
            dataType: 'xml',
            success: function (xml) {
              cachecap = xml
              resolve(xml)
            },
            error: function (error) {
                cachecap = 'error'
                resolve(error)
            }
          })
      })
 }

  /**
 * Get mapping service capabilities
 */
  const loadFailoverCapabilities = () => {
    return new Promise((resolve, reject) => {
        $.ajax({
            crossOrigin: true,
            type: 'GET',
            url: failoverUrl + 'service=WMS&request=GetCapabilities',
            dataType: 'xml',
            success: function (xml) {
                failcap = xml
                resolve('ok')
            }
        })
    })
 }

 const getFailoverUrl = () => {
    return failoverUrl
 }

 const getMMCacheCapabilities = () => {
   return mmcachecap
 }



 const loadMMCacheCapabilities = () => {
    return new Promise((resolve, reject) => {
        $.ajax({
        crossOrigin: true,
        type: 'GET',
        url: 'https://mm.buchananservices.uk/geoserver/gwc/service/wmts?REQUEST=GetCapabilities',
        dataType: 'xml',
        success: function (xml) {
            mmcachecap = xml
            resolve('ok')
        },
        error: function (error) {
            mmcachecap = 'error'
            resolve(error)
        }
        })
    })
  }

  const getCacheCapabilities = () => {
    return cachecap
  }
/**
 * gets publish date from layerconfig JSON object
 */
 const getPublishDate = (type) => {
    // if no type is supplied all publish dates by app
    let pdates = ''
    if (type === undefined) {
      for (let i in layerconfig) {
        if (pdates.indexOf(layerconfig[i].type + ' ' + layerconfig[i].publishdate) === -1) {
          pdates += layerconfig[i].type + ' ' + layerconfig[i].publishdate + ','
        }
      }
      return pdates.replace(/,(?=\s*$)/, '');
    } else {
      for (let i in layerconfig) {
        if (layerconfig[i].type === type) {
          return layerconfig[i].publishdate.split('-').reverse().join('/')
        }
      }
    }
 }

 const getLayerList = (type) => {
  // if no type is supplied return first publish date
  let layers = ''
  if (type === undefined) {
    for (let i in layerconfig) {
        layers += layerconfig[i].name + ','
    }
  } else {
    for (let i in layerconfig) {
      if (layerconfig[i].type === type) {
        layers += layerconfig[i].name + ','
      }
    }
  }
  return layers.replace(/,(?=\s*$)/, '');
}



/**
 * get both json files
 */

const init = () => {
    return loadCacheCapabilities().then((data) => {
        //console.log('OK')
      })
      .catch((error) => {
        //console.log('Caught')
        return loadFailoverCapabilities()

      })
}

const initMM = () => {
    return loadMMCacheCapabilities().then((data) => {
        //console.log('OK')
        })
        .catch((error) => {
        //console.log('Caught')
        return loadFailoverCapabilities()

        })
  //return loadMMCacheCapabilities()
}

/**
 * number standarize
 * add a 0 if the number is < than 10
 */

const leadZero = (num) => {
  if (parseInt(num) < 10) {
    num = `0${num}`
  }
  return num
}

/**
 * scale converter
 */

const getScale = (scale, resArray) => {
  for (let i = 0; i < scale.length; i++) {
    resArray.push(parseInt(scale[i]))
  }
}

// convert the temporary array of scales in resolution and populate the final array of resolutions
const setResolution = (scale, resolution) => {
  for (let i = 0; i < scale.length; i++) {
    var dpi = 25.4 / 0.28
    resolution.unshift(parseFloat((scale[i] / (39.37 * dpi))))//.toFixed(2)))
  }

}

/**
 * get fields from the xml template
 */

const getFieldValue = (myitems, i, sfield) => {
  let sval = ''
  if (typeof myitems[i].getElementsByTagName(sfield)[0] !== "undefined" && myitems[i].getElementsByTagName(sfield)[0].childNodes.length !== 0) {
    sval = myitems[i].getElementsByTagName(sfield)[0].firstChild.nodeValue
    sval = sval === '['+sfield+']' ? '' : sval
  }
  else {
    sval = ''
  }
  return sval
}

/**
 * get fields for table from the xml template
 */

const getTabValue = (myitems, i, sfield) => {
    let sval = ''
    if (myitems[i].getElementsByTagName(sfield)[0].childNodes.length !== 0) {
      sval = myitems[i].getElementsByTagName(sfield)[0].firstChild.nodeValue
      if (sval === '-1') {
        sval = '-'
      }
    }
    else {
      sval = ''
    }
    return sval
  }

/**
 * capitalize the first word in a string
 */

 const capitalLetter = (str) => {
  let string = str.split(' ')

  /**
   * get the string lenght after being split by space
   * iterate through the array
   * check if it's not a number
   * capitalize the first word
   * return the string by joining byt space
   */

  let strlen = string.length

  for (let i = 0; i < strlen; i++) {
    if (isNaN(string[i])) {
      string[i] = string[i][0].toUpperCase() + string[i].substr(1)
    }
  }
  return string.join(' ')
 }

function altFind(arr, callback) {
  for (var i = 0; i < arr.length; i++) {
    var match = callback(arr[i]);
    if (match) {
      return arr[i];
    }
  }
}

function mobileTabletView() {
    // returns true if window width is less than value
    if (window.innerWidth < 768) {
        return true
    } else {
        return false
    }
}


//detect whether an element is visible in the viewport
// argument is jquery select - e.g #id or .class
function detectViewVisible(jqselstring){

    var top_of_element = $(jqselstring).offset().top;
    var bottom_of_element = $(jqselstring).offset().top + $(jqselstring).outerHeight();
    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    var top_of_screen = $(window).scrollTop();

    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
       // Element is visible write your codes here
       // You can add animation or other codes here
       return true
    } else {
        // the element is not visible, do something else
        return false
    }

}
 /**
 * EXPORT
 */

export default {
  init,
  initMM,
  leadZero,
  getScale,
  setResolution,
  getPublishDate,
  getLayerList,
  getFieldValue,
  getTabValue,
  capitalLetter,
  altFind,
  getCacheCapabilities,
  getMMCacheCapabilities,
  mobileTabletView,
  detectViewVisible,
  getFailoverUrl
}
