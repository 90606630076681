let Cookies = require('js-cookie')

function acceptLandingCookie() {
  $('.cookiebanner ').removeClass('d-flex')
  $('.cookiebanner').addClass('d-none');
  setLandingCookie('twappcookie', 'yes', 365);
}

/**
 * 
 * @param {cookie name} cname 
 * @param {*value of i understand} cvalue 
 * @param {*set expiry days} exdays 
 */

function setLandingCookie(cname, cvalue, exdays) {
  var date = new Date();
  date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + date.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure;samesite=strict;";
}

/**
 * get langing cookie
 * @param {coockie name} cname 
 * @returns cookie name or blank if doenst exist
 */
 function getLandingCookie(cname) {
  var name = cname + "="
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var x = 0; x < ca.length; x++) {
    var c = ca[x]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}


/**
 * Check for Landing Cookie
 */
function checkLandingCookie() {
  $('#privaccept').hide()
  $('.helpStarter').hide()
  $('#privpolicy').hide()
  $('.privClose').show()
  $('.helpNormal').show()
  $('#helpClose').show()
  $('#cookieland').on('click',() => {
    acceptLandingCookie()
  })
  var cookie = getLandingCookie('twappcookie')
  if (cookie !== '') {
    $('.cookiebanner ').removeClass('d-flex')
    $('.cookiebanner ').addClass('d-none')
  } else {
    $('.cookiebanner ').removeClass('d-none')
    $('.cookiebanner ').addClass('d-flex')
  }
}
/**
 * EXPORT
 */

export default {
  checkLandingCookie
}
