/**
 * in this module there are:
 *   - hide/show divs based on window resolution
 *   - copyright year
 *   - dropdown behaviour for the tool menu
 *   - hide/show navbar/tool menu on click for mobile
 *   - general behaviour for all the buttons in the page
 *   - the zooms buttons are in traffweb.js as the map variable is there
 *   - print button behaviour
 */
import functions from './functions'

/**
 * hide/show divs based on window resolution
 * we create a separate function to export and use it later on in multiples DOM events
 */

const hideDiv = () => {
  // dont hide for now
  $('.section').css('display', 'inline-block')
  $('#drawing').css('display', 'block')
}

/**
 * we create a big function to initialize all the events inside
 * and we call this function in the main app
 */
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)")


const initEvents = (mapView, overlay) => {
  /**
   * apply the correct year in the footer
   */

  //console.log(mapView.getLayers())
  const currentYear = new Date().getFullYear()
  $('#bcCopyright').text(`Copyright Buchanan Computing ${currentYear}`)

  /**
   * dropdown behaviour
   * !! we don't use an arrow function because the this is not bind !!
   * it prevent to close the menu when you click on a sibling element
   */

  $('#dropdownTools').on('click', function (e) {
      // Check if the media query matches or is not available.
      if (!mediaQuery || mediaQuery.matches) {
        $('#accordion').toggle()
      } else {
        $('#accordion').slideToggle()
      }
    if ($('#dropdownTools em').hasClass('fa-caret-right')) {
      $('#dropdownTools em').removeClass('fa-caret-right').addClass('fa-caret-down')
    } else {
      $('#dropdownTools em').addClass('fa-caret-right').removeClass('fa-caret-down')
    }
    e.stopPropagation()
    e.preventDefault()

    // hide the popup
    overlay.setPosition(undefined)
  })

  $('#dropdownToolsMobile').on('click', function (e) {
    if ($('#navbarResponsive').hasClass('show')) {
        $('#navbarResponsive').removeClass('show')
    }
      // Check if the media query matches or is not available.
      if (!mediaQuery || mediaQuery.matches) {
        $('#toolbar').toggle()
      } else {
        $('#toolbar').slideToggle()
      }

    if ($('#dropdownToolsMobile em').hasClass('fa-caret-right')) {
      $('#dropdownToolsMobile em').removeClass('fa-caret-right').addClass('fa-caret-down')
    } else {
      $('#dropdownToolsMobile em').addClass('fa-caret-right').removeClass('fa-caret-down')
    }
    e.stopPropagation()
    e.preventDefault()

    // hide the popup
    overlay.setPosition(undefined)
  })

  /**
  * accordion behaviour
  */

  $('.collapse').on('show.bs.collapse', () => {
    $('.collapse').collapse('hide')
  })

  /**
   * hide navbar/tool menu section on click for mobile
   */
   $('.navbar-toggler').on('click',() => {
    if (functions.mobileTabletView()) {
      if ($('#toolbar').css('display','block')) {
        $('#toolbar').css('display','none')
        $('#dropdownToolsMobile em').addClass('fa-caret-right').removeClass('fa-caret-down')
      }
      // close dash panel if open
      if ($('.dashboardselect-btn em').hasClass('fa-arrow-circle-left')) {
        $('.dashboardselect-btn').click()
      }
    }
  })

    // hide the navbar when you change the selected layer (PC)
    $('#select_layers').on('change',() => {
      if (functions.mobileTabletView()) {
        $('#accordion').toggleClass('show')
      }
    })


  /**
   * cookie button behaviour
   * hide/show the correct:
   *   - title
   *   - accept cookie text
   *   - button
   *   - the X to close the modal
   */

  $('#privaccept').on('click',(e) => {
    e.preventDefault()
    // accept cookie text
    $('#privpolicy').hide()
    // I accept button for cookie
    $('#privaccept').hide()
    // title in the nav
    $('.helpStarter').hide()
    $('.helpNormal').show()
    // X to close the modal
    $('.privClose').show()
    // close button not cookie
    $('#helpClose').show()
    // close the modal
    $('#help').modal('hide')
  })

  /**
   * hide accessibility modal when the form is opening
   * we close the first modal because when you open the other modal
   * and you are again in the first one it doesn't work, you cannot click on anything
   */

  $('#accsAform').on('click',() => {
    $('#accessibility').modal('hide')
  })

  /**
   * reset the form on close
   */

  $('#accsform').on('hidden.bs.modal', function () {
    $('.accsformdiv').css('display', 'block')
    $('.accsformsuccess').addClass('d-none')
    $('.accsformerror').addClass('d-none')
    $('.accsform').trigger('reset')
  })

  /**
   * when the page load hide:
   *   - the result div of the gazetteer search
   *   - the loading div used on a gazetteer search
   */

  $('#searchcont').hide()
  $('.loading').hide()

  /**
   * map control buttons
   * the zoom buttons has to works with zoom because if we set them with Resolution doesn't respect the min/max level
   */

  $('#zoomin').on('click', () => {
    mapView.getView().setZoom(mapView.getView().getZoom() + 1)
    // hide the popup
    overlay.setPosition(undefined)
  })

  $('#zoomout').on('click', () => {
    mapView.getView().setZoom(mapView.getView().getZoom() - 1)
    // hide the popup
    overlay.setPosition(undefined)
  })

  // prevent to open a popup by clicking on the map rotation arrow
  $('.ol-rotate-reset').on('click',(e) => {
    e.stopPropagation()
    // hide the popup
    overlay.setPosition(undefined)
  })

  /**
   * hide/show the printer div on click
   */

  $('#printbtn').on('click',(e) => {
    e.preventDefault()
    $('.printopt').toggleClass('hide')
    // hide the popup
    overlay.setPosition(undefined)
  })

  /**
   * hide/show the themes selector div on click
   */

  $('#themesbtn').on('click',(e) => {
    e.preventDefault()
    $('#mapthemeDialog').modal('show')
    // hide the popup
    overlay.setPosition(undefined)
  })

  /** hide foam marker legend */
  let urlpos = window.location.search.replace('?', '')
  $('#foam_marker').css('display','none')
  if (urlpos.search('markpos') > -1) {
    $('#foam_marker').css('display','block')
  }
  /**
   * overview map
   * animation on the overview div when show/hide it
   */

  $('#showovmap').on('click', () => {
    $('.ol-overviewmap-map').slideToggle()
    // hide the popup
    overlay.setPosition(undefined)
  })
} // end initEvent

/**
 * EXPORT
 */

export default {
  hideDiv,
  initEvents
}
